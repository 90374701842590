import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import BekMenu from "../components/bek-menu";

export const Head = () => {
  return (
    <Seo
      title="鍼灸メニュー"
      description="体の痛み, 心の悩み、美容、鎮痛治療全般 初診4,900円等"
    />
  );
};

const BiyoukanMenuPage = () => {
  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "美容館メニュー",
      link: "/biyoukan-menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "アクセス・お問い合わせ",
      link: "/biyoukan/#access",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
  ];
  // ここでデータを定義するか、あるいはAPIから取得するかします
  const menuItems = [
    {
      name: "自分でお手入れできるカット",
      firstVisit: "0歳〜中学生まで:3,300 \n 高校生以上:4,950",
    },
    {
      name: "ヘアドネーション",
      firstVisit: "11,000",
    },
    {
      name: "多彩なカラーリング",
      firstVisit: "6,600〜22,000",
    },
    {
      name: "カラーリタッチ",
      firstVisit: "6,600〜",
    },
    {
      name: "髪質改善トリートメント",
      firstVisit: "5,500〜",
    },
    {
      name: "多種デザインパーマ",
      firstVisit: "6,600〜22,000",
    },
    {
      name: "デジタルパーマ",
      firstVisit: "17,600〜",
    },
    {
      name: "縮毛矯正",
      firstVisit: "18,700〜",
    },
    {
      name: "コスメストレート",
      firstVisit: "14,300〜",
    },
    {
      name: "育毛・頭皮環境改善",
      firstVisit: "5,500〜",
    },
    {
      name: "シャンプー・ブロー",
      firstVisit: "3,300〜",
    },
    {
      name: "セット・メイク",
      firstVisit: "5,500〜",
    },
    {
      name: "美歯口ホワイトニング",
      firstVisit: "1回:3,980 \n 1ヶ月通い放題:11,000",
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(64, 155, 57, 0.2)"}>
      <div className="kamata-body">
        <div className="kamata-body-text">
          <div className="biyoukann-menu-text-title">
            <h1>美 容 館 メ ニ ュ ー</h1>
          </div>
        </div>
        <BekMenu title={""} menuItems={menuItems} />
        <div className="to-top-box">
          <Link to="/biyoukan" className="menu-button">
            美容館トップページへ戻る
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default BiyoukanMenuPage;
